import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import * as yup from 'yup';
import TextField from '@mui/material/TextField';
import apiClient from '../../apiClient';

const validationSchema = yup.object({
  name: yup
    .string('Enter your first name')
    .required('Your first name is required'),
  surname: yup
    .string('Enter your surname')
    .required('Your surname is required'),
  phone: yup
    .string('Enter your phone number')
    .required('Your phone number is required'),
  email: yup
    .string('Enter your email')
    .email('Enter a valid email'),
  kid: yup
    .string('Enter your child\'s name')
    .required('Your child\'s name is required'),
  home: yup
    .string('Enter your home area (e.g. Mbangweni)')
    .required('Your home area is required'),
  school: yup
    .string('Enter your child\'s school (e.g. Evergreen)')
    .required('Your child\'s school is required'),
  emergency_person: yup
    .string('Enter your emergency contact name')
    .required('Your emergency contact name is required'),
  emergency_phone: yup
    .string('Enter your emergency phone number')
    .required('Your emergency phone number is required'),
  gardian_name: yup
    .string('Enter your gardian name number'),
  gardian_phone: yup
    .string('Enter your gardian phone number'),
});

export default function SignupForm() {
  const [activeStep, setActiveStep] = React.useState(0);

  const formik = useFormik({
    initialValues: {
      name: '',
      surname: '',
      phone: '',
      email: '',
      kid: '',
      home: '',
      school: '',
      emergency_person: '',
      emergency_phone: '',
      gardian_name: '',
      gardian_phone: '',
      distance: '0',
      payment_amount: '0',
      payment_frequency: 'Monthly'
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      apiClient.post(
        `/api/clients-fmk/`,
        values,
        {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        })
        .then((res) => {
          alert("Thank you for signing up! A FetchMyKid agent will confirm your successful registration shortly");
        })
        .catch((e) => {
          alert("Something went wrong. Please try again");
        });
    },
  });

  const handleNext = () => {
    formik.handleSubmit();

    formik.validateForm()
      .then(() => {
        if(activeStep === 0){
          // console.log(!Boolean(formik.errors.name));
          if(!Boolean(formik.errors.name) && !Boolean(formik.errors.surname) && !Boolean(formik.errors.phone)){
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
          }
        }
        else if(activeStep === 1){
          if(!Boolean(formik.errors.kid) && !Boolean(formik.errors.home) && !Boolean(formik.errors.school)){
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
          }
        }
        else if(activeStep === 2){
          if(!Boolean(formik.errors.emergency_person) && !Boolean(formik.errors.emergency_phone)){
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
          }
        }
      });
    
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  

  const steps = [
    {
      label: 'Step 1 - Parent Information',
      description: <div>
                      <TextField
                        fullWidth
                        className='my-2'
                        id="name"
                        name="name"
                        label="Name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                      />
                      <TextField
                        fullWidth
                        className='my-2'
                        id="surname"
                        name="surname"
                        label="Surname"
                        value={formik.values.surname}
                        onChange={formik.handleChange}
                        error={formik.touched.surname && Boolean(formik.errors.surname)}
                        helperText={formik.touched.surname && formik.errors.surname}
                      />
                      <TextField
                        fullWidth
                        className='my-2'
                        id="phone"
                        name="phone"
                        label="Phone Number"
                        value={formik.values.phone}
                        onChange={formik.handleChange}
                        error={formik.touched.phone && Boolean(formik.errors.phone)}
                        helperText={formik.touched.phone && formik.errors.phone}
                      />
                      <TextField
                        fullWidth
                        className='my-2'
                        id="email"
                        name="email"
                        label="Email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                      />
                  </div>,
    },
    {
      label: 'Step 2 - Kid Information',
      description: <div>
                      <TextField
                        fullWidth
                        className='my-2'
                        id="kid"
                        name="kid"
                        label="Child's Name"
                        value={formik.values.kid}
                        onChange={formik.handleChange}
                        error={formik.touched.kid && Boolean(formik.errors.kid)}
                        helperText={formik.touched.kid && formik.errors.kid}
                      />
                      <TextField
                        fullWidth
                        className='my-2'
                        id="home"
                        name="home"
                        label="Home Area"
                        placeholder='Home Area (e.g Mbangweni)'
                        value={formik.values.home}
                        onChange={formik.handleChange}
                        error={formik.touched.home && Boolean(formik.errors.home)}
                        helperText={formik.touched.home && formik.errors.home}
                      />
                      <TextField
                        fullWidth
                        className='my-2'
                        id="school"
                        name="school"
                        label="School"
                        value={formik.values.school}
                        onChange={formik.handleChange}
                        error={formik.touched.school && Boolean(formik.errors.school)}
                        helperText={formik.touched.school && formik.errors.school}
                      />
                  </div>,
    },
    {
      label: 'Step 3 - Emergency Information',
      description: <div>
                      <TextField
                        fullWidth
                        className='my-2'
                        id="emergency_person"
                        name="emergency_person"
                        label="Emergency Contact Full Name"
                        value={formik.values.emergency_person}
                        onChange={formik.handleChange}
                        error={formik.touched.emergency_person && Boolean(formik.errors.emergency_person)}
                        helperText={formik.touched.emergency_person && formik.errors.emergency_person}
                      />
                      <TextField
                        fullWidth
                        className='my-2'
                        id="emergency_phone"
                        name="emergency_phone"
                        label="Emergency Contact Phone Number"
                        value={formik.values.emergency_phone}
                        onChange={formik.handleChange}
                        error={formik.touched.emergency_phone && Boolean(formik.errors.emergency_phone)}
                        helperText={formik.touched.emergency_phone && formik.errors.emergency_phone}
                      />
                      <TextField
                        fullWidth
                        className='my-2'
                        id="gardian_name"
                        name="gardian_name"
                        label="Gardian Full Name"
                        value={formik.values.gardian_name}
                        onChange={formik.handleChange}
                        error={formik.touched.gardian_name && Boolean(formik.errors.gardian_name)}
                        helperText={formik.touched.gardian_name && formik.errors.gardian_name}
                      />
                      <TextField
                        fullWidth
                        className='my-2'
                        id="gardian_phone"
                        name="gardian_phone"
                        label="Gardian Phone Number"
                        value={formik.values.gardian_phone}
                        onChange={formik.handleChange}
                        error={formik.touched.gardian_phone && Boolean(formik.errors.gardian_phone)}
                        helperText={formik.touched.gardian_phone && formik.errors.gardian_phone}
                      />
                  </div>,
    },
  ];

  return (
    <Box sx={{ maxWidth: 400, marginTop: '150px', margin: '150px auto' }}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              optional={
                index === 2 ? (
                  <Typography variant="caption">Last step</Typography>
                ) : null
              }
            >
              {step.label}
            </StepLabel>
            <StepContent>
              {step.description}
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    disabled={index === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {index === steps.length - 1 ? 'Finish' : 'Next'}
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography>All steps completed and submitted</Typography>
          <a href="/">
            Go To Home Page
          </a>
        </Paper>
      )}
    </Box>
  );
}
