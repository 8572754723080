import React from 'react';

import Navbar from '../components/Navbar';
import BannerPrivacy from '../components/Banner/privacy';


export default function Privacy() {

    return (
        <>
            <Navbar/>
            <BannerPrivacy/>
        </>
    );
}