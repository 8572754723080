import React, {useState} from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import emailjs from 'emailjs-com';

const schools = [
    {
      id: 0,
      label: 'Evergreen',
    },
    {
      id: 1,
      label: 'Kidzville',
    },
    {
      id: 2,
      label: 'Brilliant Stars',
    },
    {
      id: 3,
      label: 'Montessori Life Primary',
    },
    {
      id: 4,
      label: 'EduCope',
    },
    {
      id: 5,
      label: 'Bahai',
    },
    {
      id: 6,
      label: 'Little School',
    },
    {
      id: 7,
      label: 'Sifundzani Primary',
    },
    {
      id: 8,
      label: 'Sifundzani High',
    },
    {
      id: 9,
      label: 'LARN Montessori',
    },
    {
      id: 10,
      label: 'Premier',
    },
    {
      id: 11,
      label: 'Other',
    },
];

const neighbourhoods = [
    {
      id: 0,
      label: 'QueensGate',
    },
    {
      id: 1,
      label: 'Sdwashini',
    },
    {
      id: 2,
      label: 'Mbangweni',
    },
    {
      id: 3,
      label: 'Woodlands',
    },
    {
      id: 4,
      label: 'Beverly Hills',
    },
    {
      id: 5,
      label: 'Nkwalini',
    },
    {
      id: 6,
      label: 'Mahwalala',
    },
    {
      id: 7,
      label: 'Checkers',
    },
    {
      id: 8,
      label: 'Makholokholo',
    },
    {
      id: 9,
      label: 'Mona Flats',
    },
    {
      id: 10,
      label: 'Fonteyn',
    },
    {
      id: 11,
      label: 'Kent Rock',
    },
    {
      id: 12,
      label: 'Town',
    },
    {
      id: 13,
      label: 'Other',
    },
    
];

export default function Communication() {
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [neighbourhoodId, setNeighbourhoodId] = useState(-1);
    const [schoolId, setSchoolId] = useState(-1);
    const [successHidden, setSuccessHidden] = useState(true);
    const [errorHidden, setErrorHidden] = useState(true);
    const [vaildHidden, setVaildHidden] = useState(true);
    const [loadingHidden, setLoadingHidden] = useState(true);
    const [state, setState] = useState({
        tripTo: true,
        tripFrom: true,
    });
    const [data, setData] = useState({ 
          name: '', 
          phone: '', 
          neighbourhood: '', 
          school: '', 
          tripTo: true, 
          tripFrom: true
    });

    const { tripTo, tripFrom } = state;
    const error = [tripTo, tripFrom].filter((v) => v).length === 0;
    
    const handleChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.checked,
        });

        setData({
            ...data,
            [event.target.name]: event.target.checked,
        });
    };

    const handleChangeName = (event) => {
        setName(event.target.value);

        setData({
            ...data,
            name: event.target.value,
        });

        setVaildHidden(true);
    };

    const handleChangePhone = (event) => {
        setPhone(event.target.value);

        setData({
            ...data,
            phone: event.target.value,
        });

        setVaildHidden(true);
    };

    const handleChangeNeighbourhood = (event) => {
        setNeighbourhoodId(event.target.value);

        setData({
            ...data,
            neighbourhood: neighbourhoods[event.target.value].label,
        });

        setVaildHidden(true);
    };

    const handleChangeSchools = (event) => {
        setSchoolId(event.target.value);

        setData({
            ...data,
            school: schools[event.target.value].label,
        });

        setVaildHidden(true);
    };

    const handleSubmitForm = (event) => {
        if(name === "" || phone === "" || neighbourhoodId === -1 || schoolId === -1){
            setVaildHidden(false);
        }
        else{
            setVaildHidden(true);
            setLoadingHidden(false);

            emailjs.send('service_j13fgus', 'template_c0ft0ws', data, 'user_34RSwvrGY1Vl42S1Hfar4')
            .then(function(response) {
                //  console.log('SUCCESS!', response.status, response.text);
                // setFormStatus('success');
                    setLoadingHidden(true);
                    setSuccessHidden(false);
                    setErrorHidden(true);
                }, function(error) {
                //  console.log('FAILED...', error);
                // setFormStatus('fail');
                    setLoadingHidden(true);
                    setSuccessHidden(true);
                    setErrorHidden(false);
            });
        }

    };

    return(
        <>
            <section className="form7 cid-sveyl9loLK" id="form7-3">
                <div className="container">
                    <div className="mbr-section-head">
                        <h3 className="mbr-section-title mbr-fonts-style align-center mb-0 display-2">
                            <strong>Get a Quotation</strong>
                        </h3>

                    </div>
                    <div className="row justify-content-center mt-4">
                        <div className="col-lg-8 mx-auto mbr-form" data-form-type="formoid">
                            <form action="https://mobirise.eu/" method="POST" className="mbr-form form-with-styler mx-auto" data-form-title="Form Name">
                                {/* <input type="hidden" name="email" data-form-email="true" value="RLYAAfovOnyGxxCPwouguACKDxEuXHauR/sD2n2PL+VsHPIjCLZcw+4QTMbrcdiZ2Ma1NL4G/1MzTSSCzt8ORbDtecnZ7ZYj4j3DxMQZhPgBK9O1EFp5dG5GYKs9Km0N"/> */}
                                <p className ="mbr-text mbr-fonts-style align-center mb-4 display-7">Fill in this form and we will contact you via WhatsApp</p>
                                <div className ="row">
                                <div hidden="hidden" data-form-alert="" className ="alert alert-success col-12">Thanks for filling out the form!We will contact you within the next 48 hours</div>
                                <div hidden="hidden" data-form-alert-danger="" className ="alert alert-danger col-12">Oops...!some
                                problem!</div>
                                </div>
                                {/* <div className ="dragArea row">
                                <div className ="col-lg-12 col-md-12 col-sm-12 form-group" data-for="name">
                                <input type ="text" name="name" placeholder="Name" data-form-field="name" className ="form-control" id="name-form7-3"/>
                                </div>

                                <div data-for="phone" className ="col-lg-12 col-md-12 col-sm-12 form-group">
                                <input type ="tel" name="phone" placeholder="WhatsApp Phone Number" data-form-field="phone" className ="form-control" id="phone-form7-3"/>
                                </div>
                                <div className ="col-auto mbr-section-btn align-center">
                                <button type ="submit" className ="btn btn-primary display-4">Submit</button>
                                </div>
                                </div> */}
                                <Box
                                    component="form"
                                    sx={{
                                        '& .MuiTextField-root': { width: '100%' },
                                    }}
                                    noValidate
                                    autoComplete="off"
                                >
                                    <div>
                                        <TextField 
                                            required
                                            id="outlined-basic" 
                                            label="Name" 
                                            variant="outlined" 
                                            value={name}
                                            onChange={handleChangeName}
                                            className="mb-4"
                                            style={{fontFamily: "'Indie Flower', handwriting",}}
                                        />
                                        <TextField 
                                            required
                                            id="outlined-basic" 
                                            label="WhatsApp Phone Number" 
                                            variant="outlined" 
                                            value={phone}
                                            onChange={handleChangePhone}
                                            className="mb-4"
                                            helperText="Enter Phone Number that you use for WhatsApp (7-------)"
                                        />
                                        <TextField
                                            required
                                            id="outlined-select-currency-native"
                                            select
                                            label="Select School"
                                            value={schoolId}
                                            onChange={handleChangeSchools}
                                            helperText="Please school your kid goes to"
                                            className="mb-4"
                                        >
                                        {schools.map((option) => (
                                            <MenuItem key={"school-"+option.id} value={option.id}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                        </TextField>
                                        <TextField
                                            required
                                            id="outlined-select-currency"
                                            select
                                            label="Select Neighborhood"
                                            value={neighbourhoodId}
                                            onChange={handleChangeNeighbourhood}
                                            helperText="Please neighborhood where your kid should be picked up from"
                                            className="mb-1"
                                        >
                                        {neighbourhoods.map((option) => (
                                            <MenuItem key={"neighbourhood-"+option.id} value={option.id}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                        </TextField>
                                    </div>
                                    <Box sx={{ display: 'flex' }}>
                                        <FormControl
                                            required
                                            error={error}
                                            component="fieldset"
                                            sx={{ m: 3 }}
                                            variant="standard"
                                        >
                                            <FormLabel component="legend">Transport from:</FormLabel>
                                            <FormGroup>
                                            <FormControlLabel
                                                control={
                                                <Checkbox checked={tripTo} onChange={handleChange} name="tripTo" />
                                                }
                                                label="Home to School"
                                            />
                                            <FormControlLabel
                                                control={
                                                <Checkbox checked={tripFrom} onChange={handleChange} name="tripFrom" />
                                                }
                                                label="School to Home"
                                            />
                                            </FormGroup>
                                            <FormHelperText>Select at least one</FormHelperText>
                                        </FormControl>
                                    </Box>
                                    <div>
                                        <Button 
                                            disabled={!loadingHidden || !successHidden}
                                            style={{
                                                width: '100%', 
                                                backgroundColor: '#6592e6', 
                                                borderRadius: '100px', 
                                                fontFamily: "'Indie Flower', handwriting",
                                                fontSize: '1.1rem',
                                                lineHeight: '1.5',
                                                fontWeight: 600,

                                                // '&:hover':{
                                                //     backgroundColor: '#2260d2 !important'
                                                // }
                                            }}
                                            variant="contained"
                                            onClick={() => {handleSubmitForm()}}
                                        >Request Quotation</Button>
                                    </div>
                                    <center hidden={loadingHidden} className="mt-4"><CircularProgress /></center>
                                    <div hidden={successHidden} data-form-alert="" className ="alert alert-success col-12 mt-4">Thanks for filling out the form! We will contact you within the next 12 hours</div>
                                    <div hidden={errorHidden} data-form-alert-danger="" className ="alert alert-danger col-12 mt-4">Oops...! Something went wrong, please try again.</div>
                                    <div hidden={vaildHidden} data-form-alert-danger="" className ="alert alert-danger col-12 mt-4">Please fill in all the required fields</div>
                                </Box>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            <section className="contacts2 cid-swxwte9XMJ" id="contacts2-a">
                <div className="container">
                    <div className="mbr-section-head">
                        <h3 className="mbr-section-title mbr-fonts-style align-center mb-0 display-2">
                            <strong>Contact Us</strong>
                        </h3>

                    </div>
                    <div className="row justify-content-center mt-4">
                        <div className="card col-12 col-md-6">
                            <div className="card-wrapper">
                                <div className="image-wrapper">
                                    <span className="mbr-iconfont mobi-mbri-phone mobi-mbri"></span>
                                </div>
                                <div className="text-wrapper">
                                    <h6 className="card-title mbr-fonts-style mb-1 display-5">
                                        <strong>Phone (Call / WhatsApp)</strong></h6>
                                    <span className="mbr-text mbr-fonts-style display-5"><a href="tel:+26879360602" className="text-primary">79360602</a></span> /
                                    <span className="mbr-text mbr-fonts-style display-5"><a href="tel:+26879639644" className="text-primary">79639644</a></span> /
                                    <span className="mbr-text mbr-fonts-style display-5"><a href="tel:+26876647907" className="text-primary">76647907</a></span> 
                                </div>
                            </div>
                        </div>
                        <div className="card col-12 col-md-6">
                            <div className="card-wrapper">
                                <div className="image-wrapper">
                                    <span className="mbr-iconfont mobi-mbri-letter mobi-mbri"></span>
                                </div>
                                <div className="text-wrapper">
                                    <h6 className="card-title mbr-fonts-style mb-1 display-5">
                                        <strong>Email</strong>
                                    </h6>
                                    <p className="mbr-text mbr-fonts-style display-5"><a href="mailto:fetchmykid@bpdteam.com" className="text-primary">fetchmykid@bpdteam.com</a></p>
                                </div>
                            </div>
                        </div>
                        {/* <div className="card col-12 col-md-6">
                            <div className="card-wrapper">
                                <div className="image-wrapper">
                                    <span className="mbr-iconfont mobi-mbri-globe mobi-mbri"></span>
                                </div>
                                <div className="text-wrapper">
                                    <h6 className="card-title mbr-fonts-style mb-1 display-5">
                                        <strong>Website</strong></h6>
                                    <p className="mbr-text mbr-fonts-style display-5">
                                        <a href="https://fetchmykid.bpdteam.com" className="text-primary">fetchmykid.bpdteam.com</a></p>
                                </div>
                            </div>
                        </div> */}

                    </div>
                </div>
            </section>
        </>
    );
}