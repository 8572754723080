import React from 'react';
import DriverCard from './card';

export default function Drivers() {
    return(
        <section className="team1 cid-sveyvm3RZN" id="team1-5">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <h3 className="mbr-section-title mbr-fonts-style align-center mb-4 display-2"><strong>The Drivers</strong></h3>
                        <p className="mbr-section-subtitle mbr-fonts-style align-center mb-5 display-5">Get to know the drivers</p>
                    </div>
                    <div className="col-sm-6 col-lg-4">
                        <DriverCard 
                            image="assets/images/bandile.png"
                            name="Bandile P Dlamini"
                            role="Software Developer"
                            description="A software developer by profession and a tech-guy in general. Therefore, he seeks to find ways to use technology to make transportation kids more efficient, safe and reliable. Bandile is a hard-worker and puts passion in all that he does."
                            facebook="https://www.facebook.com/profile.php?id=100005765928796"
                            instagram="https://www.instagram.com/bandile_dlamini/"
                            website="https://bandilepdlamini.com"
                        />
                    </div>
                    {/* <div className="col-sm-6 col-lg-4">
                        <DriverCard 
                            image="assets/images/sanele.png"
                            name="Sanele Dlamini"
                            role="Plumber and Carpenter"
                            description="A plumber and carpenter by profession. Sanele has a soft-spot for kids as he just recently became a father to his first born son, born in 2022. He is a responsible and trustworthy man, due to his good service delivery he is well know and well trusted by his clients in his own business."
                            facebook="https://www.facebook.com/ntateng.boateng"
                            instagram="https://www.instagram.com/saneledlamini60/"
                            website=""
                        />
                    </div> */}
                    {/* <div className="col-sm-6 col-lg-4">
                        <DriverCard 
                            image="assets/images/sisekelo.png"
                            name="Sisekelo Shabangu"
                            role="Driver"
                            description="A driver by profession. Sisekelo has experience being a personal driver as well as a truck and tractor driver. He loves to drive and therefore you can trust him to safely transport your child. He is a very friendly, respectful and loyal young man."
                            facebook=""
                            instagram=""
                            website=""
                        />
                    </div> */}
                    <div className="col-sm-6 col-lg-4">
                        <DriverCard 
                            image="assets/images/andile.png"
                            name="Andile Dlamini"
                            role="Manager & Driver"
                            description="A driver by profession. Andile is a humble man and father, he loves to drive and loves children, therefore you can trust him to safely transport your child. He is a very friendly, respectful and responsible man."
                            facebook="https://www.facebook.com/mduduzia.hlubi"
                            instagram=""
                            website=""
                        />
                    </div>
                    <div className="col-sm-6 col-lg-4">
                        <DriverCard 
                            image="assets/images/khanya.png"
                            name="Khaya Mavuso"
                            role="Driver"
                            description="A driver and gym instructor by profession. Khaya is a humble and friendly man, he is very careful and observant with all things, therefore you can trust him to safely transport your child. He is also a hard-working individual and self-motivated."
                            facebook="https://www.facebook.com/profile.php?id=100086597087102&notif_id=1673960957070463&notif_t=follow_profile&ref=notif"
                            instagram=""
                            website=""
                        />
                    </div>
                </div>
            </div>
        </section>
    );
}


