// BrowserRouter is the router implementation for HTML5 browsers (vs Native).
// Link is your replacement for anchor tags.
// Route is the conditionally shown component based on matching a path to a URL.
// Switch returns only the first matching route rather than all matching routes.
import {
  BrowserRouter as Router,
  // Link,
  Route,
  Switch,
} from 'react-router-dom';
import React from 'react';
import Home from './pages/home';
import SignUp from './pages/signup';
import NotFound from './pages/404';
import { createBrowserHistory } from 'history';
import Privacy from './pages/privacy';

// We give each route either a target `component`, or we can send functions in `render` or `children` 
// that return valid nodes. `children` always returns the given node whether there is a match or not.
function App() {
  const history = createBrowserHistory ? createBrowserHistory() : [];

  return (
    <Router history={history}> 
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/signup" exact component={SignUp} />
        <Route path="/privacy-policy-mobile-app" exact component={Privacy} />
        {/* <Route
          path="/contact"
          render={() => <h1>Contact Us</h1>} />
        <Route path="/blog" children={({match}) => (
          <li className={match ? 'active' : ''}>
            <Link to="/blog">Blog</Link>
          </li>)} /> */}
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
}

export default App;
