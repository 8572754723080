import React from 'react';

export default function BannerPrivacy() {
    return(
        <section className="header1 cid-svexBEkLH9 mbr-fullscreen mbr-parallax-background" id="header1-1">
            <div className="mbr-overlay" style={{opacity: '0.7', backgroundColor: 'rgb(68, 121, 217)'}}></div>

            <div className="container mt-5">
                <div className="row">
                    <div className="col-12 col-lg-8">
                        <h1 className="mbr-section-title mbr-fonts-style mb-3 mt-5 display-1"><strong>Privacy Policy</strong></h1>
                        <p className="mbr-text mbr-fonts-style mb-3 display-7"><strong>Effective Date: </strong>15 January 2025</p>
                        <p className="mbr-text mbr-fonts-style display-7">FetchMyKid ("we," "our," or "us") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, and share your information when you use our mobile application ("the App"). By using the App, you agree to the terms outlined in this policy.</p>
                        
                        <ol>
                            <li className="mbr-text mbr-fonts-style mb-3 display-2">
                                <h3 className="mbr-text mbr-fonts-style mb-3 display-2"><strong>Information We Collect</strong></h3>
                                <ol style={{listStyleType: 'lower-alpha'}}>
                                    <li className="mbr-text mbr-fonts-style mb-3 display-5">
                                        <h3 className="mbr-text mbr-fonts-style mb-3 display-5"><strong>Personal Information</strong></h3>
                                        <p className="mbr-text mbr-fonts-style display-7">We may collect personal information such as:</p>
                                        <ul>
                                            <li className="mbr-text mbr-fonts-style display-7">
                                                <p className="mbr-text mbr-fonts-style display-7">Name</p>
                                            </li>
                                            <li className="mbr-text mbr-fonts-style display-7">
                                                <p className="mbr-text mbr-fonts-style display-7">Email address</p>
                                            </li>
                                            <li className="mbr-text mbr-fonts-style display-7">
                                                <p className="mbr-text mbr-fonts-style display-7">Phone number</p>
                                            </li>
                                            <li className="mbr-text mbr-fonts-style display-7">
                                                <p className="mbr-text mbr-fonts-style display-7">Child's name, school, and pickup/drop-off schedule</p>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="mbr-text mbr-fonts-style mb-3 display-5">
                                        <h3 className="mbr-text mbr-fonts-style mb-3 display-5"><strong>Location Data</strong></h3>
                                        <p className="mbr-text mbr-fonts-style display-7">To provide accurate pickup and drop-off services, we collect and process location data when the App is in use.</p>
                                    </li>
                                    <li className="mbr-text mbr-fonts-style mb-3 display-5">
                                        <h3 className="mbr-text mbr-fonts-style mb-3 display-5"><strong>Usage Data</strong></h3>
                                        <p className="mbr-text mbr-fonts-style display-7">We may collect information about how you use the App, including:</p>
                                        <ul>
                                            <li className="mbr-text mbr-fonts-style display-7">
                                                <p className="mbr-text mbr-fonts-style display-7">Device type</p>
                                            </li>
                                            <li className="mbr-text mbr-fonts-style display-7">
                                                <p className="mbr-text mbr-fonts-style display-7">Operating system</p>
                                            </li>
                                            <li className="mbr-text mbr-fonts-style display-7">
                                                <p className="mbr-text mbr-fonts-style display-7">App version</p>
                                            </li>
                                            <li className="mbr-text mbr-fonts-style display-7">
                                                <p className="mbr-text mbr-fonts-style display-7">Usage patterns and crash reports</p>
                                            </li>
                                        </ul>
                                    </li>
                                </ol>
                            </li>
                            <li className="mbr-text mbr-fonts-style mb-3 display-2">
                                <h3 className="mbr-text mbr-fonts-style mb-3 display-2"><strong>How We Use Your Information</strong></h3>
                                <p className="mbr-text mbr-fonts-style display-7">We use the information we collect to:</p>
                                <ul>
                                    <li className="mbr-text mbr-fonts-style display-7">
                                        <p className="mbr-text mbr-fonts-style display-7">Provide and improve our services.</p>
                                    </li>
                                    <li className="mbr-text mbr-fonts-style display-7">
                                        <p className="mbr-text mbr-fonts-style display-7">Ensure safe and reliable transport services for children.</p>
                                    </li>
                                    <li className="mbr-text mbr-fonts-style display-7">
                                        <p className="mbr-text mbr-fonts-style display-7">Communicate with you about updates, services, and notifications.</p>
                                    </li>
                                    <li className="mbr-text mbr-fonts-style display-7">
                                        <p className="mbr-text mbr-fonts-style display-7">Analyze usage to enhance user experience.</p>
                                    </li>
                                </ul>
                            </li>
                            <li className="mbr-text mbr-fonts-style mb-3 display-2">
                                <h3 className="mbr-text mbr-fonts-style mb-3 display-2"><strong>Sharing Your Information</strong></h3>
                                <p className="mbr-text mbr-fonts-style display-7">We do not sell or rent your personal information to third parties. However, we may share your data:</p>
                                <ul>
                                    <li className="mbr-text mbr-fonts-style display-7">
                                        <p className="mbr-text mbr-fonts-style display-7">With service providers who help us operate the App.</p>
                                    </li>
                                    <li className="mbr-text mbr-fonts-style display-7">
                                        <p className="mbr-text mbr-fonts-style display-7">To comply with legal obligations.</p>
                                    </li>
                                    <li className="mbr-text mbr-fonts-style display-7">
                                        <p className="mbr-text mbr-fonts-style display-7">To protect the rights and safety of our users or others.</p>
                                    </li>
                                </ul>
                            </li>
                            <li className="mbr-text mbr-fonts-style mb-3 display-2">
                                <h3 className="mbr-text mbr-fonts-style mb-3 display-2"><strong>Data Security</strong></h3>
                                <p className="mbr-text mbr-fonts-style display-7">We take reasonable measures to protect your information from unauthorized access, alteration, disclosure, or destruction. However, no system is completely secure, and we cannot guarantee absolute security.</p>
                            </li>
                            <li className="mbr-text mbr-fonts-style mb-3 display-2">
                                <h3 className="mbr-text mbr-fonts-style mb-3 display-2"><strong>Your Rights</strong></h3>
                                <p className="mbr-text mbr-fonts-style display-7">Depending on your location, you may have the following rights:</p>
                                <ul>
                                    <li className="mbr-text mbr-fonts-style display-7">
                                        <p className="mbr-text mbr-fonts-style display-7">Access and request a copy of your data.</p>
                                    </li>
                                    <li className="mbr-text mbr-fonts-style display-7">
                                        <p className="mbr-text mbr-fonts-style display-7">Request corrections to your data.</p>
                                    </li>
                                    <li className="mbr-text mbr-fonts-style display-7">
                                        <p className="mbr-text mbr-fonts-style display-7">Request deletion of your data.</p>
                                    </li>
                                    <li className="mbr-text mbr-fonts-style display-7">
                                        <p className="mbr-text mbr-fonts-style display-7">Withdraw consent for data processing where applicable.</p>
                                    </li>
                                </ul>
                                <p className="mbr-text mbr-fonts-style display-7">To exercise these rights, contact us at <span className="mbr-text mbr-fonts-style display-7"><a href="mailto:fetchmykid@bpdteam.com" className="text-primary">fetchmykid@bpdteam.com</a></span>.</p>
                            </li>
                            <li className="mbr-text mbr-fonts-style mb-3 display-2">
                                <h3 className="mbr-text mbr-fonts-style mb-3 display-2"><strong>Children's Privacy</strong></h3>
                                <p className="mbr-text mbr-fonts-style display-7">FetchMyKid complies with applicable laws to protect children's privacy, including the Children's Online Privacy Protection Act (COPPA) and other regulations. Parents or guardians must provide consent for the collection of personal information about their children.</p>
                            </li>
                            <li className="mbr-text mbr-fonts-style mb-3 display-2">
                                <h3 className="mbr-text mbr-fonts-style mb-3 display-2"><strong>Changes to This Privacy Policy</strong></h3>
                                <p className="mbr-text mbr-fonts-style display-7">We may update this Privacy Policy from time to time. Changes will be posted in the App with an updated "Effective Date."</p>
                            </li>
                            <li className="mbr-text mbr-fonts-style mb-3 display-2">
                                <h3 className="mbr-text mbr-fonts-style mb-3 display-2"><strong>Contact Us</strong></h3>
                                <p className="mbr-text mbr-fonts-style display-7">If you have any questions or concerns about this Privacy Policy or our practices, please contact us at:</p>
                                <p className="mbr-text mbr-fonts-style display-7">Email: <span className="mbr-text mbr-fonts-style display-7"><a href="mailto:fetchmykid@bpdteam.com" className="text-primary">fetchmykid@bpdteam.com</a></span></p>
                                <p className="mbr-text mbr-fonts-style display-7">Phone: <span className="mbr-text mbr-fonts-style display-7"><a href="tel:+26879360602" className="text-primary">(+268) 7936 0602</a></span></p>
                                <p className="mbr-text mbr-fonts-style display-7">Address: 40 Somhlolo Road, Queens Gate, Mbabane</p>
                            </li>
                        </ol>
                        
                        
                        <div className="mbr-section-btn mt-3"><a className="btn btn-primary display-4" href="https://chatwith.io/s/bpd-team" target="_blank" rel="noreferrer"><span className="socicon socicon-whatsapp mbr-iconfont mbr-iconfont-btn"></span>Chat on WhatsApp</a></div>
                    </div>
                </div>
            </div>
        </section>
    );
}